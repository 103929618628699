<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item title="Header">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.header.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="6">
                        <ValidationProvider
                          name="Subtitle"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Subtitle"
                            label-for="mc-subtitle"
                          >
                            <b-form-input
                              id="mc-subtitle"
                              placeholder="Subtitle"
                              v-model="model.header.subtitle"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div
                        v-for="(img, index) in model.header.images"
                        :key="index"
                      >
                        <div v-if="img != null" class="text-center">
                          <img
                            :src="img"
                            class="img-fluid rounded m-1 pointer"
                            alt=""
                            style="width: 150px; height: 150px"
                          /><br />
                          <div class="edit-avatar">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              @click="deleteFileHeader(img)"
                              variant="danger"
                              size="sm"
                              class="float-center btn-icon rounded-circle"
                              style=""
                            >
                              <feather-icon icon="XCircleIcon" />
                            </b-button>
                          </div>
                        </div>
                      </div>

                      <div class="pl-1">
                        <div
                          style="
                            text-align: center;
                            border-radius: 10px;
                            background: #e5e7e9;
                            padding-top: 25px;
                            padding-bottom: 25px;
                            cursor: pointer;
                            width: 150px;
                            height: 150px;
                          "
                          class="mr-1 mt-1 mb-1"
                        >
                          <label
                            for="file-upload"
                            class="btn-edit"
                            style="font-size: 40px; cursor: pointer"
                          >
                            <feather-icon icon="UploadIcon" />
                            <p style="font-size: 14px">Upload Image Header</p>
                          </label>
                          <input
                            id="file-upload"
                            class="input-file-avatar d-none"
                            type="file"
                            @change="handleFileUploadImages($event)"
                          />
                        </div>
                        <small class="text-danger" v-show="image_size"
                          >The image file is too large, <br />allowed maximum
                          size is {{ max_file_size }}MB</small
                        >
                      </div>
                    </div>
                  </app-collapse-item>

                  <app-collapse-item title="Description">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Title Description"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.description.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="6">
                        <ValidationProvider
                          name="Image Description"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Image" label-for="mc-icon">
                            <b-form-file
                              v-model="model.description.image"
                              @change="storeDescriptionImage"
                              placeholder="Choose a file..."
                            />
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <ValidationProvider
                          name="Detail Description"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Description"
                            label-for="mc-title"
                          >
                            <b-form-textarea
                              id="textarea-default"
                              placeholder="Description"
                              rows="4"
                              v-model="model.description.description"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Features">
                    <b-row>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section Fatures's</h5>
                        <span v-for="(page, idx) in model.features" :key="idx">
                          <b-row>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Features Title ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Title"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Title"
                                    v-model="model.features[idx].title"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Features Subitle ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Subtitle"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Subtitle"
                                    v-model="model.features[idx].subtitle"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Logo ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group label="Logo" label-for="mc-icon">
                                  <b-form-file
                                    v-model="model.features[idx].logo"
                                    @change="storeImageFeature($event, idx)"
                                    placeholder="Choose a file..."
                                  />
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <button
                            type="button"
                            class="btn btn-danger mr-2"
                            @click="deleteFeatures(idx)"
                            v-if="model.features.length > 1"
                          >
                            <i class="fa fa-trash"></i> Delete Row Features</button
                          >&nbsp;
                          <div class="divider my-1">
                            <div class="divider-text">End of Row Features</div>
                          </div>
                        </span>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="addMoreFeatures"
                        >
                          Add More Features
                        </b-button>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Teams">
                    <b-row>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section Team's</h5>
                        <span v-for="(page, idx) in model.teams" :key="idx">
                          <b-row>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Team Name ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group label="Name" label-for="mc-title">
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Name"
                                    v-model="model.teams[idx].name"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Team Position ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Position"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Position"
                                    v-model="model.teams[idx].position"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="12">
                              <ValidationProvider
                                :name="'Teams Profile ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Profile"
                                  label-for="mc-icon"
                                >
                                  <b-form-file
                                    v-model="model.teams[idx].avatar"
                                    @change="storeImageTeams($event, idx)"
                                    placeholder="Choose a file..."
                                  />
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                              <ValidationProvider
                                :name="
                                  'Team Soacial Media Team ' +
                                  (idx + 1) +
                                  ' Twitter'
                                "
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Social Media Twitter"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Social Media Twitter"
                                    v-model="model.teams[idx].social[0].url"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                              <ValidationProvider
                                :name="
                                  'Team Soacial Media Team ' +
                                  (idx + 1) +
                                  ' LinkedIn'
                                "
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Social Media LinkedIn"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Social Media LinkedIn"
                                    v-model="model.teams[idx].social[1].url"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="12">
                              <ValidationProvider
                                :name="'Team Description ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Description"
                                  label-for="mc-title"
                                >
                                  <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Description"
                                    rows="4"
                                    v-model="model.teams[idx].description"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <button
                            type="button"
                            class="btn btn-danger mr-2"
                            @click="deleteTeams(idx)"
                            v-if="model.teams.length > 1"
                          >
                            <i class="fa fa-trash"></i> Delete Row Team</button
                          >&nbsp;
                          <div class="divider my-1">
                            <div class="divider-text">End of Row Teams</div>
                          </div>
                        </span>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="addMoreTeams"
                        >
                          Add More Teams
                        </b-button>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Our Company">
                    <b-row>
                      <b-col md="12">
                        <h5 class="font-600 mb-2">Section Our Companies</h5>
                        <span v-for="(page, idx) in model.companies" :key="idx">
                          <b-row>
                            <b-col md="6">
                              <ValidationProvider
                                :name="'Companies Title ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Title"
                                  label-for="mc-title"
                                >
                                  <b-form-input
                                    id="mc-title"
                                    placeholder="Title"
                                    v-model="model.features[idx].title"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                              <ValidationProvider
                                :name="'Our Companies Logo ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group label="Logo" label-for="mc-icon">
                                  <b-form-file
                                    v-model="model.companies[idx].logo"
                                    @change="storeImageCompanies($event, idx)"
                                    placeholder="Choose a file..."
                                  />
                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col md="12">
                              <ValidationProvider
                                :name="'Companies Description ' + (idx + 1)"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <b-form-group
                                  label="Description"
                                  label-for="mc-title"
                                >
                                  <b-form-textarea
                                    id="textarea-default"
                                    placeholder="Description"
                                    rows="4"
                                    v-model="model.companies[idx].description"
                                  />

                                  <p class="fs-6 text-danger">
                                    {{ errors[0] }}
                                  </p>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </span>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Trusted Brand">
                    <b-row>
                      <b-col md="12">
                        <ValidationProvider
                          name="Title Trusted Brand"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.trustedBrands.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div
                        v-for="(image, index) in model.trustedBrands.brands"
                        :key="index"
                      >
                        <div v-if="image != null" class="text-center">
                          <img
                            :src="image"
                            class="img-fluid rounded m-1 pointer"
                            alt=""
                            style="width: 150px; height: 150px"
                          /><br />
                          <div class="edit-avatar">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              @click="deleteFile(image)"
                              variant="danger"
                              size="sm"
                              class="float-center btn-icon rounded-circle"
                              style=""
                            >
                              <feather-icon icon="XCircleIcon" />
                            </b-button>
                          </div>
                        </div>
                      </div>

                      <div class="pl-1">
                        <div
                          style="
                            text-align: center;
                            border-radius: 10px;
                            background: #e5e7e9;
                            padding-top: 25px;
                            padding-bottom: 25px;
                            cursor: pointer;
                            width: 150px;
                            height: 150px;
                          "
                          class="mr-1 mt-1 mb-1"
                        >
                          <label
                            for="file-uploads"
                            class="btn-edit"
                            style="font-size: 40px; cursor: pointer"
                          >
                            <feather-icon icon="UploadIcon" />
                            <p style="font-size: 14px">Upload Image</p>
                          </label>
                          <input
                            id="file-uploads"
                            class="input-file-avatar d-none"
                            type="file"
                            @change="handleFileUpload($event)"
                          />
                        </div>
                        <small class="text-danger" v-show="image_size"
                          >The image file is too large, <br />allowed maximum
                          size is {{ max_file_size }}MB</small
                        >
                      </div>
                    </div>
                  </app-collapse-item>

                  <app-collapse-item title="Contact Us">
                    <b-row>
                      <b-col md="6">
                        <ValidationProvider
                          name="Contact Us Title"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Title" label-for="mc-title">
                            <b-form-input
                              id="mc-title"
                              placeholder="Title"
                              v-model="model.contactUs.title"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Subtitle"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Subtitle"
                            label-for="mc-subtitle"
                          >
                            <b-form-input
                              id="mc-subtitle"
                              placeholder="Subtitle"
                              v-model="model.contactUs.subtitle"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Email" label-for="mc-email">
                            <b-form-input
                              id="mc-email"
                              placeholder="Email"
                              v-model="model.contactUs.email"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="6">
                        <ValidationProvider
                          name="Contact Us Phone"
                          :rules="{
                            regex: /^(\+62|62|0)8[1-9][0-9]{6,11}$/,
                            required: true,
                          }"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            label="Mobile Phone"
                            label-for="mc-no-telepon"
                          >
                            <b-form-input
                              id="mc-no-telepon"
                              placeholder="Mobile Phone"
                              v-model="model.contactUs.phoneNumber"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col md="12">
                        <ValidationProvider
                          name="Contact Us  Address"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group label="Address" label-for="mc-title">
                            <b-form-textarea
                              id="textarea-default"
                              placeholder="Address"
                              rows="4"
                              v-model="model.contactUs.address"
                            />

                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>

            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'content-about-us' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      collapseType: "margin",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "content/about-us",
        redirect: "content-about-us",
        language: "languages",
        upload: "misc/upload",
      },
      model: {
        header: {
          title: "",
          subtitle: "",
          images: [],
        },
        description: {
          title: "",
          description: "",
          image: "",
        },
        contactUs: {
          title: "",
          subtitle: "",
          email: "",
          phoneNumber: "",
          address: "",
        },
        features: [
          {
            logo: "",
            title: "",
            subtitle: "",
          },
        ],
        teams: [
          {
            avatar: "",
            name: "",
            position: "",
            description: "",
            social: [
              {
                url: "",
                type: "twitter",
              },
              {
                url: "",
                type: "linkedin",
              },
            ],
          },
        ],
        companies: [
          {
            logo: "",
            title: "",
            description: "",
          },
        ],
        trustedBrands: {
          title: "",
          brands: [],
        },
      },
      messages: "",
      languages: [],
    };
  },
  mounted() {
    let _ = this;
    _.getLanguage();
    _.getData();
  },
  methods: {
    handleFileUpload(event) {
      console.log("hallo");
      let _ = this;
      let file = event.target.files[0];

      let formData = new FormData();
      formData.append("file", file);

      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.trustedBrands.brands.push(res.data.uploadedFile);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    handleFileUploadImages(event) {
      let _ = this;
      let file = event.target.files[0];

      let formData = new FormData();
      formData.append("file", file);

      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.header.images.push(res.data.uploadedFile);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    storeDescriptionImage(e) {
      let _ = this;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.description.image = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addMoreFeatures() {
      let _ = this;
      _.model.features.push({
        logo: null,
        title: null,
        subtitle: null,
      });
    },

    deleteFeatures(x) {
      let _ = this;
      _.model.features.splice(x, 1);
      // _.selectedContents.splice(x, 1);
    },

    addMoreTeams() {
      let _ = this;
      _.model.teams.push({
        avatar: "",
        name: "",
        position: "",
        description: "",
        social: [
          {
            url: "",
            type: "twitter",
          },
          {
            url: "",
            type: "linkedin",
          },
        ],
      });
    },

    deleteTeams(x) {
      let _ = this;
      _.model.teams.splice(x, 1);
      // _.selectedContents.splice(x, 1);
    },

    storeImageTeams(e, idx) {
      let _ = this;
      console.log(e);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.teams[idx].avatar = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    storeImageFeature(e, idx) {
      let _ = this;
      console.log(e);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.features[idx].logo = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    storeImageCompanies(e, idx) {
      let _ = this;
      console.log(e);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      axios
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.companies[idx].logo = res.data.uploadedFile;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteFile(url) {
      let _ = this;
      _.model.trustedBrands.brands = _.model.trustedBrands.brands.filter(
        (e) => e != url
      );
    },

    deleteFileHeader(url) {
      let _ = this;
      _.model.header.images = _.model.header.images.filter((e) => e != url);
    },

    getLanguage() {
      let _ = this;
      axios.get(_.config.language).then((response) => {
        let _ = this;
        _.languages = response.data.rows;
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            _.model.title = _.model.header.title;
            _.model.subtitle = _.model.header.subtitle;
          });
      }
    },
    save() {
      let _ = this;

      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .patch(
            "https://ch-api.demoapp.xyz/v2/content/about-us/" +
              _.$route.params.id,
            _.model
          )
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
  watch: {
    "model.code"(newValue) {
      this.model.code = newValue.toLowerCase();
    },
  },
};
</script>
